import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-awesome-styled-grid"
import styled from "styled-components"

const CURRENCY_FORMAT = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
})

const StatusText = styled.p`
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
`

const Card = styled.div`
  border-radius: 3px;
  border: 2px solid #666;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 0.2rem;
`

const Value = styled.p`
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin: 0;
`

const Label = styled.p`
  font-size: 1rem;
  text-align: center;
  margin: 0;
`

const formatCurrency = amount => CURRENCY_FORMAT.format(amount)

const Dashboard = () => {
  const [error, setError] = useState(false)
  const [token, setToken] = useState(undefined)
  const [statistics, setStatistics] = useState(undefined)

  useEffect(() => {
    fetch(
      "https://www.adcell.de/api/v2/user/getToken?userName=248480&password=V7Zezsg5xnxs4Li"
    )
      .then(response => response.json())
      .then(({ data }) => {
        setToken(data.token)
      })
      .catch(() => {
        setError(true)
      })
  }, [])

  useEffect(() => {
    if (token === undefined) return
    fetch(
      `https://www.adcell.de/api/v2/affiliate/dashboard/getQuickInfo?token=${token}`
    )
      .then(response => response.json())
      .then(({ data }) => {
        setStatistics(data)
      })
      .catch(() => {
        setError(true)
      })
  }, [token])

  return (
    <Container>
      <Row>
        <Col>
          {token === undefined && <StatusText>Lade Daten...</StatusText>}
          {error && <StatusText>Fehler</StatusText>}
        </Col>
      </Row>
      {statistics && (
        <Row justify="center">
          <Col xs={12} sm={6} md={4} lg={3}>
            <Card>
              <Value>{formatCurrency(statistics.totalCommissionSum)}</Value>
              <Label>Guthaben gesamt</Label>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <Card>
              <Value>{formatCurrency(statistics.accountBalance)}</Value>
              <Label>Guthaben</Label>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <Card>
              <Value>{formatCurrency(statistics.openCommissionSum)}</Value>
              <Label>Guthaben offen</Label>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <Card>
              <Value>{formatCurrency(statistics.todayCommissionSum)}</Value>
              <Label>Guthaben heute</Label>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default Dashboard
